import { Stack } from "@mui/material";
import { DirectUpload } from "@rails/activestorage";
import { useInput } from "ra-core";
import React, { ChangeEventHandler, FC } from "react";

import LinearProgressWithLabel from "./LinearProgressWithLabel";

const DirectUploadFileInput: FC<{
  accept: string;
  label: string;
  source: string;
}> = ({ accept, label, source }) => {
  const { id, field, fieldState } = useInput({ source });
  const [progress, setProgress] = React.useState(0);
  const handleChange: ChangeEventHandler = (event) => {
    Array.from((event.target as any).files as File[]).forEach((file) =>
      uploadFile(file)
    );
  };

  const uploadFile = (file: File) => {
    const url = "/rails/active_storage/direct_uploads";

    const progressListener = (xhr: XMLHttpRequest) => {
      xhr.upload.addEventListener(
        "progress",
        (event) => {
          setProgress((event.loaded / event.total) * 100);
        },
        false
      );
    };

    const upload = new DirectUpload(file, url, {
      directUploadWillStoreFileWithXHR: progressListener,
    });

    upload.create((error, blob) => {
      if (!error) {
        field.onChange(blob.signed_id);
      }
    });
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <label htmlFor={id}>{label}</label>
      <input accept={accept} type="file" onChange={handleChange} />
      <LinearProgressWithLabel sx={{ width: "100%" }} value={progress} />
      <input id={id} type="hidden" {...field} required={true} />
      {fieldState.error && <span>{fieldState.error.message}</span>}
    </Stack>
  );
};

export default DirectUploadFileInput;
